<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Edit Informasi
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          title="edit Informasi"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit" style="line-height: 32px;">
            <b-field label="Nama Prodi" horizontal>
              <span v-if="setting">{{ setting.prodi }}</span>
            </b-field>
            <b-field label="Fakultas" horizontal>
              <span v-if="setting">{{ setting.fakultas }}</span>
            </b-field>
            <b-field label="Universitas" horizontal>
              <span v-if="setting">{{ setting.universitas }}</span>
            </b-field>
            <hr />
            <b-field label="Buka" horizontal>
               <b-datetimepicker
                  v-model="form.buka"
                  :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                  locale="id-ID"
                  >
              </b-datetimepicker>
            </b-field>
            <b-field label="Tutup" horizontal>
              <b-datetimepicker
                  v-model="form.tutup"
                  :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                  locale="id-ID"
                  :min-datetime="form.buka"
                  >
              </b-datetimepicker>
            </b-field>
            <b-field label="Message" horizontal>
              <b-input maxlength="200" type="textarea"  v-model="form.message"></b-input>
            </b-field>
            <b-field label="Filter Konsentrasi" horizontal>
              <v-select v-model="form.isFilterByKonsentrasi" label="name" :options="status" :reduce="x => x.id"></v-select>
            </b-field>
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import vSelect from 'vue-select'
import CardComponent from '@/components/CardComponent'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Form',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    vSelect
  },
  data () {
    return {
      isLoading: false,
      form: {
        buka: new Date(),
        tutup: new Date(),
        isFilterByKonsentrasi: null
      },
      createdReadable: null,
      isProfileExists: false,
      setting: {},
      status: [
        {
          id: 0,
          name: 'Tidak Aktif'
        },
        {
          id: 1,
          name: 'Aktif'
        }
      ]
    }
  },
  computed: {
    titleStack () {
      return ['Dashboard', 'Setting Proposal']
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Edit informasi'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'edit_informasi.edit' }
      } else {
        return '/'
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'Edit Informasi'
      } else {
        return 'Dashboard'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Informasi'
      } else {
        return 'Tambah Informasi'
      }
    }
  },
  mounted () {
    const app = this
    axios
      .get('/setting_proposal')
      .then((r) => {
        const result = r.data.data[0]
        app.setting = result
        app.form.buka = new Date(this.setting.buka)
        app.form.tutup = new Date(this.setting.tutup)
        app.form.isFilterByKonsentrasi = parseInt(result.isFilterByKonsentrasi)
        app.form.message = this.setting.message
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  },
  methods: {
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })
      if (r.data.status === 'ok') {
        this.$router.push({ name: 'setting_proposal' })
      }
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    submit () {
      const data = this.form
      data.buka = data.buka.toISOString().replace('T', ' ').replace('Z', '')
      data.tutup = data.tutup.toISOString().replace('T', ' ').replace('Z', '')
      this.isLoading = true

      axios
        .put('/setting_proposal/1', data)
        .then(this.handleResponse)
        .catch(this.handleError)
    }
  }
}
</script>
